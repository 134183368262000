// Bootstrap 4 class button.close renamed to button.closeModal becouse button.close class is overwrited by chat component
.modal-header {
    .closeModal {
        margin-top: -2px;
        float: right;
        font-size: 1.5rem;
        font-family: 'ProximaNovaExtraBold';
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 #fff;
        opacity: 1;
        &:focus, &:hover {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            opacity: 1;
        }
    }
    button.closeModal {
        -webkit-appearance: none;
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
    }
}

// TODO: use bootstrap <del> tag
.strikethrough {
    text-decoration: line-through;
    color: #999;
}
#service-explanation-content {
    p {
        line-height: 2rem;
    }
}

.scrollbar-in-add-user-emails-form {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.service_contract_pdf_width {
    max-width: 800px;
}

.bank-id-wrapper {
    margin : 80px 0;
    .bank-id-image {
        width: 100%;
        max-width: 120px;
        cursor: pointer;
        margin-bottom: 12px;
    }
}

.pdf-display-wrapper {
    max-width: 800px;
    display: grid;
    place-items: center;
}
.text-wrap {
    display: inline-block;
    word-break: break-all;
    overflow-wrap: break-word;
}

.holder-image-wrapper {
    height: 285px;
    line-height: 285px;
    img {
        max-height: 100%;
        max-width: 100%;
    }
}
.w-40 {
    width: 40%;
}
.w-30 {
    width: 30%;
}
.w-20 {
    width: 20%;
}
.btn {
    > i.fa {
        line-height: inherit !important;
    }
}


// Save Creditsafe password styles

.save-cs-password {
    .info-blocks {
    	width: 100%;
    	margin: auto;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
        padding-left: 40px;
    }

    .content-block {
    	width: 100%;
        max-width: 700px;
    	height: auto;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
    	color: #6f6f6f;
    	background-color: #ffffff;
    	-webkit-border-radius: 4px;
    	-moz-border-radius: 4px;
    	-ms-border-radius: 4px;
    	-o-border-radius: 4px;
    	border-radius: 4px;
    	font-size: 14px;
    	padding: 40px 40px 40px 60px;
    	float: left;
    	-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	text-align: left;
    	position: relative;

    	margin-bottom: 40px;

        background: #ffffff;
    }

    .number {
        position: absolute;
        top: 40px;
        left: -40px;
        width: 40px;
        height: 56px;
        color: #ffffff;
        background-color: #fb7929;
        font-size: 24px;
        padding: 18px 16px 18px 16px;
        border-radius: 40px 0px 0px 40px;
        text-align: center;
        -webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    	-o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        line-height: 1 !important;
    }

    .img-icon {
    	width: 120px;
    }

    .content-block__left {
    	width: 30%;
    	height: auto;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
    	padding: 0px 0px 0px 0px;
    	float: left;
    }

    .content-block__right {
    	width: 70%;
    	height: auto;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
    	padding: 0px 0px 0px 16px;
    	float: left;
        clear: right;
    }

    .content-item--heading {
    	line-height: 110%;
    	color: #363636;
    	font-size: 24px;
        font-weight: 600;
    }

    .spacer {
    	height: 10px;
    	width: 20px;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	-ms-box-sizing: border-box;
    	-o-box-sizing: border-box;
    	box-sizing: border-box;
    	display: block;
    }

    .text--medium {
    	font-size: 16px;
    	line-height: 140%;
    }

    .text--strong {
        font-weight: 600;
    }

    .clearBoth {
        clear: both;
    }

    .has-danger {
        color: #d80027;
    }
}

#user_globalsearch_users_list {
    p {
        margin-bottom: 0;
    }
}

.cursor_default {
    cursor: default !important;
}

.flex_fill {
    flex: 1 !important;
}
